<template>
  <section class="main-section main-section-maintenance">
    <div class="main-layout" v-for="(m, i) in maintenance" :key="i">
      <div class="maintenance-section">
        <h3 class="maintenance-name-div">
          <span class="maintenance-name">{{ m.title }}</span>
        </h3>
        <div class="maintenance-page" v-for="(c, mi) in m.content" :key="mi">
          <h3 class="maintenance-title">
            <span class="maintenance-title-text">{{ c.title }}</span>
            <span class="maintenance-title-bg"></span>
          </h3>
          <div class="maintenance-content" v-html="c.content"></div>
          <div v-if="c.img !== ''" class="maintenance-img">
            <v-img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/maintenance-' + c.img + '.png'"></v-img>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    maintenance: [
      {
        title: '面板的清洁保养',
        content: [
          {
            title: '双饰面板',
            content: '<p>双饰⾯板表⾯⽐较耐磨、耐划痕、耐酸碱、耐烫，⽇常清洁保养中的注意事项相对来说也会⽐较少。在清洁上，⼀般污垢只需沾少量清⽔清洗并及时擦⼲即可。⽐较严重的脏污，使⽤⽐较温和的清洁剂附着于微湿软布上或使⽤特殊清洁喷剂，擦拭后即可除净。</p><br/><p> 需要注意的是，在清洁双饰⾯板材时应选择正确的清洁剂，带有磨料以及化学性较强的⽤剂应谨慎、尽量避免使⽤，以免损伤板材表⾯。</p>',
            img: '1'
          },
          {
            title: '装饰膜面板',
            content: '<p>⽇常⽆需特殊护理，⼀般污渍，只需⽤⼲湿⽑⼱擦拭即可。如表⾯有固化的污渍，可⽤温和洗洁精兑⽔或淡酒精（10%浓度以下）进⾏擦拭。不要使⽤研磨剂、溶剂、抛光剂、蜡进⾏清洁。</p>',
            img: '2'
          },
          {
            title: '烤漆⾯板',
            content: '<p>1.防划：烤漆门板表面的漆膜厚度在0.3mm左右，在使用过程中应避尖锐东西划门板表面。</p><br/><p>2.防酸碱：油漆的抗酸碱性普遍较差，在使用过程中应尽量避免酸性、碱性物质接触烤漆门板表面，以免腐蚀门板的漆膜，如果在使用过程中不小心将酱油、醋洒在门板表面应及时用干净棉布醮少许清水擦拭。</p><br/><p>3.防撞击：烤漆门板在使用过程中应尽量避免撞击门板，优质烤漆门板的漆膜硬度在2H左右，即10公斤的重物正面砸在门板表面，门板可能会出现坑，门板漆膜不会出现大面积脱落。</p><br/><p>4.防高温、阳光直射：烤漆门板的漆膜会因高温发生变色，因此在使用过程中应尽量避免阳光直射门板。</p><br/><p>5.清洗：在日常使用过程中，厨房内很难避免油烟，当门板表面有油烟、油渍时，可用洗涤剂擦拭即可，用洗涤剂:水=1:10的洁净棉布擦拭。</p>',
            img: '3'
          },
          {
            title: '铝框玻璃面板',
            content: '<p>铝框型材用软布沾清水或中性洗涤剂，不要用普通肥皂和洗衣粉，更不能用去污粉、洗厕精等强酸碱的清洁剂，这样会腐蚀型材表面，铝框型材表面应避免硬物划伤。</p><p>玻璃在日常使用过程中，难免会出现污渍或者灰尘，可使用清水中兑入中性洗涤剂清洁，用软布打湿，拧干后对玻璃进行擦拭。擦拭玻璃时不能用力过大，以免玻璃损坏伤及自身安全。</p>',
            img: '4'
          },
          {
            title: '实木面板',
            content: '<p>1、使用软布清洁实木家具。经常用软布顺着木面的纹理，为家具去尘，去尘之前，应在软布上沾点清水，勿多感觉潮气即可，不要用化学制剂，以免损伤漆面。木制家具为天然材质较易受损，使用时应避免碰撞及冲击，使用时应避免将文件或仟何物品放置放木制品表面拖拽或磨滑，以及长期放置重物，以免产生磨痕或压痕；</p><br/><p>2、注意保持实木家具处于合适的湿度。实木家具在较干燥的环境下使用时，需采用人工加湿措施。如:定期用软布沾水擦拭家具或使用室内加湿器;</p><br/><p>3、为实木家具打蜡。定期打蜡，每隔6-12个月，用膏状蜡为家具上一层蜡。上蜡之前，应先用较温和的非碱性肥皂水将旧蜡抹除;</p>',
            img: ''
          }
        ]
      },
      {
        title: '台面的清洁保养',
        content: [
          {
            title: '岩板台面',
            content: '<p>日常清洁上只需清水即可擦拭干净即可，对于结痂的污渍，可将温水倒在污渍上，待污渍溶解后用干净的抹布擦洗即可。岩板普遍比较薄，因此在日常使用中不要在上面大力剁石材，以免造成台面受损破裂。</p>',
            img: '5'
          },
          {
            title: '石英石台面',
            content: '<p>1、⽤洗涤⽤品进⾏擦洗。擦洗后可以⽤家⾥的汽⻋蜡或者家具蜡涂于表层，待⼲了以后再⽤⼲布来回摩擦，这样 会给台⾯增加⼀层保护膜。特别需要注意的就是台⾯拼接缝的地⽅如果有污渍的话，建议及时擦洗，并给这⾥重点上蜡，在这⾥上蜡频率可以⾼点。</p><br/><p>2、请勿将⾼温物体直接放在⽯英⽯上⾯，这样有可能会给⽯英⽯表⾯带来损坏。也不要⽤⼒敲打台⾯或者直接在 台⾯切东⻄，这样会有损台⾯。</p><br/><p>3、表⾯尽量保持⼲燥⽔中含有⼤量的漂⽩剂和⽔垢，停留时间过久后，会使台⾯颜⾊变浅，影响美观。若此现象 发⽣，喷上碧丽珠或者清洗液后反复擦拭到光亮即可。</p><br/><p>4、严防烈性化学品接触表⾯⽯英⽯台⾯具有持久抗伤害能⼒，但仍需避免与烈性化学品接触，例如去油漆剂、金属清洗剂、炉灶清洗剂。不要接触亚甲基氯化物、丙酮、 强酸清洗剂。若不慎与以上物品接触，⽴即⽤⼤量肥皂⽔冲洗表⾯。</p>',
            img: ''
          }
        ]
      }
    ]
  }),
  created () {
    this.$store.commit('updateActiveNav', null)
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/about.css';
</style>
